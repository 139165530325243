import React, { Component } from "react";

import "./SortButton.sass";

class SortButton extends Component {
  state = {
    is_selected: false,
  };

  handleOnSetSelected = () => {
    this.setState((prevState) => ({ is_selected: !prevState.is_selected }));
  };

  render() {
    return (
      <button
        {...this.props}
        onClick={() => {
          this.props.onClick();
          this.handleOnSetSelected();
        }}
        className={`sort-button ${this.state.is_selected ? "selected" : ""}`}
      >
        {this.props.children}
      </button>
    );
  }
}

export default SortButton;
