import React, { Component } from "react";
import "./App.sass";
import Content from "./Content";
import Events from "./Events";
import Toolbar from "./Toolbar";
import Header from "./Header";
import KeywordButtons from "./KeywordButtons";
import Sidebar from "./Sidebar";
import { sortArray, sortByDueDate } from "../lib/arrayHelpers";
import SortButton from "./SortButton";
import PasswordModal from "./PasswordModal";

class App extends Component {
  state = {
    events: {
      title: "",
      search_placeholder: "",
      search_keyword_placeholder: "",
      events_password: "",
      protected_content_text: "",
      events: [],
    },

    show_pwd_modal: false,
    events_keywords: [],
    keywords_list: [],
    events_list: [],
    sort_types: [],
    headerTitle: "",
    header_backgroundColor: "",
    page_background_color: "",
    search_event_term: "",
    search_keyword_term: "",
    header_font_color: "",
    filters: {
      include: [],
      exclude: [],
    },
    is_open_sidebar: false,
  };

  toggleSidebar = () => {
    this.setState((prevState) => ({
      ...prevState,
      is_open_sidebar: !prevState.is_open_sidebar,
    }));
  };

  handleEventSearch = (value) => {
    this.setState({ search_event_term: value });
    const f = this.state.events.events.slice();
    const filtered = f.filter(
      (i) =>
        i.title
          .toLowerCase()
          .indexOf(this.state.search_event_term.toLowerCase()) !== -1
    );

    this.setState({ events_list: filtered });
  };

  handleKeyWordSearch = (evt) => {
    const { value: search_term } = evt.target;
    this.setState({ search_keyword_term: search_term });
    const list = this.state.keywords_list.slice();

    const result = list.filter(
      (i) => i.toLowerCase().indexOf(search_term.toLowerCase()) !== -1
    );

    this.setState({ events_keywords: result });
  };

  handleSetFilterInclude = (keyword) => {
    const include_array = this.state.filters.include.slice();
    const exclude_array = this.state.filters.exclude.slice();

    const is_include = include_array.indexOf(keyword);
    const is_exclude = exclude_array.indexOf(keyword);

    if (is_exclude > -1) {
      exclude_array.splice(is_exclude, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          exclude: exclude_array,
        },
      }));
    }

    if (is_include > -1) {
      include_array.splice(is_include, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          include: include_array,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          include: [...prevState.filters.include, keyword],
        },
      }));
    }
  };

  handleSetFilterExclude = (keyword) => {
    const include_array = this.state.filters.include.slice();
    const exclude_array = this.state.filters.exclude.slice();

    const is_include = include_array.indexOf(keyword);
    const is_exclude = exclude_array.indexOf(keyword);

    if (is_include > -1) {
      include_array.splice(is_include, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          include: include_array,
        },
      }));
    }

    if (is_exclude > -1) {
      exclude_array.splice(is_exclude, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          exclude: exclude_array,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          exclude: [...prevState.filters.exclude, keyword],
        },
      }));
    }
  };

  handleSetEventKeywords = () => {
    const events = this.state.events.events.slice();
    let list = [];
    if (events.length > 0) {
      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        for (let j = 0; j < event.keywords?.length; j++) {
          const keyword = event.keywords[j];
          if (list.indexOf(keyword) === -1) {
            list.push(keyword);
          }
        }
      }
    }

    this.setState({ events_keywords: list, keywords_list: list });
  };

  handelSortByPriority = () => {
    const copyArray = this.state.events_list.slice();
    const sorted = sortArray(copyArray, "priority");
    this.setState({ events_list: sorted });
  };

  handleSortByDueDate = () => {
    const copyArray = this.state.events_list.slice();
    const sorted = sortByDueDate(copyArray);
    this.setState({ events_list: sorted });
  };

  handleSetSortType = (type) => {
    const array = this.state.sort_types.slice();
    const index = array.indexOf(type);
    if (index > -1) {
      array.splice(index, 1);
      this.setState((prevState) => ({
        ...prevState,
        sort_types: array,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        sort_types: [...prevState.sort_types, type],
      }));
    }
  };

  handleSortEvents = () => {
    const list_of_events = this.state.events.events.slice();
    const types = this.state.sort_types;

    if (types.length > 0) {
      types.forEach((element) => {
        switch (element) {
          case "priority":
            this.handelSortByPriority();
            break;
          case "dueDate":
            this.handleSortByDueDate();
            break;
          default:
            this.handelSortByPriority();
        }
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        events_list: list_of_events,
      }));
    }
  };

  handleSetIncludesEvents = (events, includes) => {
    let includes_arr = [];
    let includes_events = [];
    for (let i = 0; i < includes.length; i++) {
      const include = includes[i];
      for (let j = 0; j < events.length; j++) {
        if (events[j]?.keywords?.some((i) => i === include)) {
          includes_arr.push(events[j]);
        }
      }
    }
    for (let i = 0; i < includes_arr.length; i++) {
      const index = includes_events.findIndex(
        (e) => e.title === includes_arr[i].title
      );

      if (index === -1) {
        includes_events.push(includes_arr[i]);
      }
    }

    return includes_events;
  };

  handleSetExcludeEvents = (events, excludes) => {
    let events_list = events.slice();

    let titles = [];

    for (let i = 0; i < excludes.length; i++) {
      const exclude = excludes[i];
      for (let j = 0; j < events_list.length; j++) {
        if (events_list[j].keywords.some((k) => k === exclude)) {
          const title = events_list[j].title;
          titles.push(title);
        }
      }
    }

    if (titles.length > 0) {
      for (let t = 0; t < titles.length; t++) {
        const title = titles[t];
        const index = events_list.findIndex((event) => event.title === title);

        if (index > -1) {
          events_list.splice(index, 1);
        }
      }
    }

    return events_list;
  };

  handleSortByKeyword() {
    const events = this.state.events.events.slice();

    let sorted_events = [];
    const { include, exclude } = this.state.filters;

    const arr = sorted_events.length > 0 ? sorted_events : events;

    if (include.length > 0) {
      sorted_events = this.handleSetIncludesEvents(arr, include);
    }

    if (exclude.length > 0) {
      sorted_events = this.handleSetExcludeEvents(arr, exclude);
    }

    this.setState({ events_list: sorted_events });
  }

  handleCheckPassword = (pwd) => {
    if (this.state.events_password === pwd) {
      this.setState((prevState) => ({ ...prevState, show_pwd_modal: false }));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sort_types !== this.state.sort_types) {
      this.handleSortEvents();
    }

    if (prevState.events.events !== this.state.events.events) {
      this.handleSetEventKeywords();
    }

    if (prevState.filters !== this.state.filters) {
      this.handleSortByKeyword();
    }

    if (prevState.filters !== this.state.filters) {
      const { include, exclude } = this.state.filters;
      if (include.length === 0 && exclude.length === 0) {
        this.setState((prevState) => ({
          ...prevState,
          events_list: this.state.events.events,
        }));
      }
    }
  }

  componentDidMount() {
    fetch("Events.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            events: data,
            events_list: data.events,
            headerTitle: data.header_title,
            events_password: data.password || "",
            show_pwd_modal: data.password?.length > 0 ? true : false,
            header_backgroundColor: data.header_backgroundColor,
            header_font_color: data.header_font_color,
          },
          () => {
            document.title = this.state.events.title;
          }
        );
      });
  }

  render() {
    const {
      events,
      events_list,
      show_pwd_modal,
      events_keywords,
      headerTitle,
      search_event_term,
      search_keyword_term,
      header_backgroundColor,
      header_font_color,
      filters,
    } = this.state;

    const {
      title,
      search_placeholder,
      search_keyword_placeholder,
      protected_content_text,
      ...rest
    } = events;

    return (
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: events.page_background_color,
        }}
      >
        <Header
          color={header_font_color}
          backgroundColor={header_backgroundColor}
          headerTitle={headerTitle}
        />

        <Sidebar
          isOpen={this.state.is_open_sidebar}
          onClose={() => this.toggleSidebar()}
        >
          <div className="sidebar-sticky">
            <div className="sidebar-row">
              <h2>Sort by:</h2>
              <div className="sidebar-row_item">
                <SortButton onClick={() => this.handleSetSortType("priority")}>
                  Priority
                </SortButton>
                <SortButton onClick={() => this.handleSetSortType("dueDate")}>
                  Due date
                </SortButton>
              </div>
            </div>

            <div className="sidebar-row">
              <h2>Key words</h2>
              <div className="sidebar-row_item">
                <input
                  type="text"
                  value={search_keyword_term}
                  onChange={this.handleKeyWordSearch}
                  placeholder={search_keyword_placeholder}
                />
              </div>
            </div>
          </div>

          <div className="sidebar-row">
            <div className="sidebar-row_keywords">
              {events_keywords.map((keyword, index) => (
                <KeywordButtons
                  key={index}
                  keyword={keyword}
                  filters={filters}
                  setIncludes={this.handleSetFilterInclude}
                  setExcludes={this.handleSetFilterExclude}
                />
              ))}
            </div>
          </div>
        </Sidebar>
        <div className="app">
          {show_pwd_modal && (
            <PasswordModal
              isShow={show_pwd_modal}
              onCheckPassword={this.handleCheckPassword}
              text={protected_content_text}
            />
          )}

          <Content>
            <Toolbar
              events={events}
              value={search_event_term}
              placeholder={events.search_placeholder}
              onChange={this.handleEventSearch}
              onSidebarOpen={() => this.toggleSidebar()}
            />
            <Events
              eventsStyles={rest}
              events={events_list}
              protected_text={protected_content_text}
            />
          </Content>
        </div>
      </div>
    );
  }
}

export default App;
