import toDate from "./toDate";

function sortArray(array, key) {
  return array.sort((a, b) => (a?.[key] < b?.[key] ? -1 : 1));
}

function sortByDueDate(array) {
  return array.sort((a, b) =>
    Number(toDate(a.dueDate)) < Number(toDate(b.dueDate)) ? -1 : 1
  );
}

export { sortArray, sortByDueDate };
