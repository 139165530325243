import React from "react";
import ReactDOM from "react-dom";

import "./PasswordModal.sass";

class PasswordModal extends React.Component {
  state = {
    pwd: "",
  };

  checkPassword = () => {
    if (this.props.onCheckPassword(this.state.pwd)) {
      this.setState({ pwd: "" });
    }
  };

  render() {
    return ReactDOM.createPortal(
      <div
        className="modal"
        style={{
          display: this.props.isShow ? "flex" : "none",
        }}
      >
        <div className="modal-content">
          <h3>{this.props.text}</h3>
          <input
            type="password"
            value={this.state.pwd}
            placeholder="Enter password"
            onChange={(evt) => this.setState({ pwd: evt.target.value })}
          />
          <button onClick={this.checkPassword}>Check password</button>
        </div>
      </div>,
      document.body
    );
  }
}

export default PasswordModal;
